<template>
  <div class="promobox" v-if="$store.state.isLogin">
    <div @click="daypayback">
      <img src="../assets/images/banner/promo/daypayback.jpg" alt="">
    </div>
    <div @click="weekpayback">
      <img src="../assets/images/banner/promo/weekpayback.jpg" alt="">
    </div>

    <div @click="dolbalevent" style="width: 100%">
      <img src="../assets/images/banner/promo/dolbal.jpg" alt="">
    </div>

  </div>
</template>

<script>
import {INIT_USERINFO, RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../store/mutation-types";
import {
  coffetimevent, daypayback, dolbalevent,
  logout,
  owlevent,
  payback,
  payback4referrer,
  run369,
  weekpayback
} from "../network/userRequest";

export default {
  name: "PromoImagesComp",
  methods: {
    daypayback() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      daypayback().then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        if (res.data.success) {
          this.$store.dispatch('actionUserInfo')
          this.$swal({
            title: '당일 페이백 지급성공',
            type: 'success',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
      })
    },
    weekpayback() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      weekpayback().then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        if (res.data.success) {
          this.$store.dispatch('actionUserInfo')
          this.$swal({
            title: '주간 페이백 지급 성공',
            type: 'success',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
      })
    },
    dolbalevent() {
      this.$store.commit(RECEIVE_SHOW_LOADING);
      dolbalevent().then(res => {
        if (res.data.success) {
          this.$store.dispatch('actionUserInfo')
          this.$swal({
            title: '돌발 이벤트 ' + res.data.msg + '포인트 지급되였습니다',
            type: 'success',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
        this.$store.commit(RECEIVE_HIDE_LOADING);
      })
    },
  }
}
</script>

<style scoped>
.promobox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 5px 0;
}

.promobox div {
  width: 49%;
  margin-bottom: 2px;
  cursor: pointer;
  margin-bottom: 5px;
}

.promobox div img {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .promobox {

  }
}
</style>